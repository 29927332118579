<template>
  <div class="fleetDetail">
    <div class="fixed top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12" />
    <div
      class="flex flex-wrap justify-between px-8 md:px-14 w-full"
      :class="{
        'md:flex-row-reverse': $route.params.id != 'new'
      }"
    >
      <div class="w-full lg:w-2/5 lg:pl-8" v-if="$route.params.id != 'new'">
        <SlideUp>
          <div
            class="mr-4 relative flex flex-col w-full min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg p-8"
          >
            <h5 class="text-blueGray-400 uppercase font-bold text-xs mb-2">
              {{ $t("Assigned users") }}
            </h5>
            <div class="flex flex-wrap my-2">
              <span
                class="bg-coolGray-100 mb-1 cursor-pointer text-coolGray-400 border border-coolGray-200 rounded mr-1 text-xs px-2 py-1 flex items-center"
                v-for="user in hub.users"
                :key="`user-hubs${user.id}`"
                @click="
                  $router.push({ name: 'User-Detail', params: { id: user.id } })
                "
              >
                <ProfileImage class="mr-1" :user="user" :isSmall="true" />
                {{ user.name }}
              </span>
            </div>

            <MSelect
              @applySelection="applySelection"
              @cancelSelection="cancelSelection"
              :preSelected="hub.users"
              :placeholder="$t('select user(s)')"
              :options="$store.state.users.users"
              :isUser="true"
            />
          </div>
        </SlideUp>
        <div
          class="w-full my-6 relative shadow-lg rounded lg:px-8 bg-white py-4"
        >
          <!-- <QrCode
            :content="{ type: 'hub', id: $route.params.id, name: hub.name }"
            :canPrint="true"
          /> -->
          <QrCode
            v-if="hub.qr_id"
            :item="hub"
            :borderLess="true"
            :key="hub.qr_id"
          />
        </div>
      </div>
      <div class="w-full lg:w-3/5">
        <SlideUp>
          <Form :form="formData" formName="hub detail" :options="options">
            <template v-slot:aftername v-if="$route.params.id != 'new'">
              <span
                @click="handleDelete"
                class="text-rose-500 border-b-4 border-rose-500 cursor-pointer text-xs inline-block"
              >
                {{ $t("delete") }}
              </span>
            </template>
          </Form>
        </SlideUp>
      </div>
    </div>
    <div class="flex flex-wrap justify-between px-8 md:px-14 w-full">
      <SlideUp v-if="bikes && $route.params.id != 'new'">
        <ListTable
          :fullwidth="true"
          :tableName="$t('damaged assets')"
          :headers="headers"
          :tableData="damagedBikes"
          :selectedRows="selectedBikes"
        >
          <template v-slot:top-right-corner>
            <el-popover placement="bottom" trigger="click">
              <div
                class="flex justify-between cursor-pointer py-2 px-2 rounded items-center"
                @click="exportBikes"
                :class="{
                  'hover:bg-blueGray-100': selectedBikesArray.length > 0,
                  'pointer-events-none bg-warmGray-100 text-warmGray-400':
                    selectedBikesArray.length == 0
                }"
              >
                <i class="fas fa-file-export" />
                {{ $t("export data") }}
              </div>
              <!-- <div
                class="flex justify-between cursor-pointer py-2 px-2 rounded items-center"
                @click="createIssue"
                :class="{
                  'hover:bg-blueGray-100': canCreateIssue,
                  'pointer-events-none bg-warmGray-100 text-warmGray-400': !canCreateIssue
                }"
              >
                <i
                  class="fas"
                  :class="{
                    'fa-exclamation-triangle text-rose-400': !canCreateIssue,
                    'fa-caret-square-right text-emerald-400': canCreateIssue
                  }"
                />
                {{ $t("create issue") }}
              </div> -->
              <button
                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                slot="reference"
                :disabled="selectedBikes.length == 0"
              >
                {{ $t("action") }}
              </button>
            </el-popover>
          </template>
        </ListTable>
      </SlideUp>
    </div>
  </div>
</template>

<script>
import SlideUp from "@/components/transitions/SlideUp.vue";
import ListTable from "@/components/ListTable.vue";
import Form from "@/components/Form.vue";
import { mapState } from "vuex";
import formatting from "../mixins/formatting";
import { bindData } from "../helpers/form";
import customData from "../mixins/customData";
import MSelect from "../components/MSelect.vue";
import ProfileImage from "../components/ProfileImage.vue";
import QrCode from "@/components/QrCode.vue";

export default {
  name: "HubDetail",
  components: {
    SlideUp,
    MSelect,
    ProfileImage,
    ListTable,
    QrCode,
    Form
  },
  mixins: [formatting, customData],
  computed: {
    ...mapState("hubs", ["hub", "bikes"]),
    options() {
      return {
        type: this.$store.state.typeDefinitions.hub
      };
    },
    damagedBikes() {
      return this.bikes
        .filter(bike => bike.bike_damages.length > 0)
        .map(bike => bike);
    },
    selectedBikesArray() {
      return Object.keys(this.selectedBikes)
        .filter(key => this.selectedBikes[key])
        .map(id => parseInt(id));
    },
    canCreateIssue() {
      const filtered = this.getSelectedBikes();
      if (filtered.length == 0) {
        return false;
      }
      const supplier = filtered?.[0]?.supplier?.id;
      return filtered.find(bike => bike.supplier.id != supplier) ? false : true;
    }
  },
  data() {
    const isUpdate = this.$route.params.id != "new";

    return {
      selectedBikes: {},
      formData: [
        {
          section: "Hub Information",
          dispatch: isUpdate ? "hubs/updateHub" : "hubs/createHub",
          fields: [
            { key: "id", name: "id", type: "hidden", value: "" },
            {
              key: "name",
              name: "name",
              type: "text",
              value: "",
              width: "full"
            },
            {
              name: "type",
              key: "type_id",
              value: "",
              type: "selectByOptionProp",
              options: "type",
              optionLabelKey: "name"
            },
            // {
            //   parentKey: "address",
            //   key: "number",
            //   name: "number",
            //   type: "text",
            //   value: ""
            // },
            {
              parentKey: "address",
              key: "street",
              name: "street",
              type: "text",
              value: ""
            },
            {
              parentKey: "address",
              key: "city",
              name: "city",
              type: "text",
              value: ""
            },
            {
              parentKey: "address",
              key: "state",
              name: "state",
              type: "text",
              value: ""
            },
            {
              parentKey: "address",
              key: "postal_code",
              name: "postal_code",
              type: "text",
              value: ""
            },
            {
              parentKey: "address",
              key: "country",
              name: "country",
              type: "text",
              value: ""
            },
            { key: "notes", name: "notes", type: "textarea", value: "" }
          ]
        }
      ],

      headers: [
        {
          name: "",
          key: "id",
          type: "check-action",
          handleClick: id => {
            const val =
              id in this.selectedBikes ? !this.selectedBikes[id] : true;
            this.$set(this.selectedBikes, id, val);
          }
        },
        {
          name: "id",
          key: "id",
          type: "link",
          linkInfo: { name: "Fleet-Detail", routeKey: "id", varKey: "id" }
        },
        {
          name: "name",
          key: "name",
          type: ""
        },
        {
          name: "type",
          key: "type.name",
          type: "",
          filter: true
        },
        {
          name: "status",
          key: "status",
          type: "label",
          noSort: true
        },
        {
          name: "unresolved issues",
          type: "getValue",
          getValue({ bike_damages }) {
            return bike_damages.length;
          }
        },
        { name: "Supplier", key: "supplier.name", type: "", filter: true },
        // { name: "bike lock", key: "bike_lock.id", type: "" },
        // { name: "Total Shifts", key: "shift_count", type: "" },
        // { name: "Usage", key: "usages_in_min", type: "time" },
        { name: "notes", key: "notes", type: "" }
      ]
    };
  },
  watch: {
    hub() {
      bindData(this.formData, this.hub);
    }
  },
  methods: {
    handleDelete() {
      const deleteString = `${this.$t("Confirm delete hub")}: ${this.hub.name}`;
      this.$confirm(deleteString).then(() => {
        this.$store.dispatch("hubs/deleteHub", {
          hub: this.hub
        });
      });
    },
    applySelection(users) {
      this.$store.dispatch("hubs/updateHub", {
        id: this.hub.id,
        users
      });
    },
    cancelSelection() {
      this.$store.dispatch("hubs/getHub", this.$route.params.id);
    },
    getSelectedBikes() {
      return this.damagedBikes.filter(({ id }) =>
        this.selectedBikesArray.includes(id)
      );
    },
    createIssue() {
      const selectedBikes = this.getSelectedBikes();
      this.$prompt("Please enter a name for the issue", "subject line", {
        confirmButtonText: this.$t("OK"),
        cancelButtonText: this.$t("Cancel")
      })
        .then(({ value: subject }) => {
          this.$store.dispatch("tickets/createTicket", {
            subject,
            supplier_id: selectedBikes?.[0].supplier?.id,
            bikes: this.selectedBikesArray
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("canceled create issue")
          });
        });
    },
    exportBikes() {
      this.$store.dispatch("exportData/exportBikes", {
        ids: Object.keys(this.selectedBikes).map(id => parseInt(id))
      });
    }
  },
  // mounted() {
  //   if (this.$route.params.id != "new") {
  //     this.$store.dispatch("hubs/getHub", this.$route.params.id);
  //   }
  // },
  created() {
    if (this.$route.params.id != "new") {
      this.$store.dispatch("hubs/getHub", this.$route.params.id);
    }
    this.getSettings("hub", "hub");
    this.$store.dispatch("users/getAllUsers");
    this.$store.dispatch("typeDefinitions/getAllHubDefinitions");
  }
};
</script>
